// 严凯 V2.2.0 /* * @Descripttion: 特权用印查看 * @version: 2.2.1 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-03-25
14:56 * @version: 2.2.2 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-04-02 15:10 */
<template>
  <section ref="parent">
    <a-modal
      :title="title"
      :visible="visible"
      centered
      :getContainer="() => $refs.parent"
      :width="recordinfo.ableReSubmitArchive || recordinfo.ableSubmitArchive ? 1132 : 658"
      @cancel="$emit('update:visible', false)"
      @ok="parentOk"
      class="parent-modal"
    >
      <a-spin :spinning="loading">
        <div class="parent">
          <section
            class="content show-scrollbar"
            :style="{
              'max-height': recordinfo.ableReSubmitArchive || recordinfo.ableSubmitArchive ? '754px' : '600px'
            }"
          >
            <section style="width:556px">
              <a-descriptions
                title=""
                layout="vertical"
                bordered
                :column="2"
                size="small"
                class="descript"
                style="border-top: 1px solid #f0f0f0"
              >
                <a-descriptions-item label="设备编号">{{ current?.number || '-' }}</a-descriptions-item>
                <a-descriptions-item label="盖印人">{{ current?.staffName || '-' }}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript">
                <a-descriptions-item label="印章名称">{{ current?.sealName || '-' }}</a-descriptions-item>
                <a-descriptions-item label="实际盖印次数">{{ current?.practicalNumber || '-' }}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions
                title=""
                layout="vertical"
                bordered
                :column="2"
                size="small"
                style="margin-bottom: 18px"
                class="descript"
              >
                <a-descriptions-item :span="2" label="用印影像">
                  <section v-if="!current.documentFileRelVO.length">无</section>
                  <div v-viewer="printImgOptions" :images="imgList" v-else>
                    <div class="stamped-mage" v-for="(item, index) in current.documentFileRelVO" :key="item.fullFile">
                      <!-- 是否是简易款印章-->
                      <div v-if="item.deviceType !== 2">
                        <!-- 影像开启 -->
                        <div v-if="!item.pictureType">
                          <!-- 影像抓拍成功 -->
                          <div v-if="item.photoType === 1">
                            <!-- 补拍图片 -->
                            <div
                              class="seal-imgs"
                              style="margin-right:0;margin-bottom: 6px;position:relative"
                              v-if="item.imageType === 2"
                            >
                              <div>
                                <img
                                  :src="item.fullFile + '?uploadFile=104x104'"
                                  @click="previewImg(item.fullFile, index, 'printImg')"
                                  alt=""
                                  @error="reImg($event, item.fullFile, 'print')"
                                  :data-imageType="item.imageType"
                                  class="seal-img"
                                />
                                <!-- 补拍 -->
                                <i
                                  v-if="item.imageType === 2"
                                  :style="{
                                    display: 'block',
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '37px',
                                    height: '34px',
                                    background: `url(${require('@/assets/images/icon.png')})`,
                                    backgroundSize: '100%'
                                  }"
                                ></i>
                              </div>
                              <div v-if="item.deviceType" class="username-area">
                                <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                              </div>
                            </div>
                            <!-- 用印图片 -->
                            <div
                              v-if="item.imageType === 1 && item.fullFile"
                              class="seal-imgs"
                              style="margin-right:0;margin-bottom: 6px;position:relative"
                            >
                              <img
                                :src="item.fullFile + '?uploadFile=104x104'"
                                @click="previewImg(item.fullFile, index, 'printImg')"
                                alt=""
                                @error="reImg($event, item.fullFile, 'print')"
                                :data-imageType="item.imageType"
                                class="seal-img"
                              />
                              <div v-if="item.deviceType" class="username-area">
                                <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                              </div>
                            </div>
                            <!-- 同步中 -->
                            <div class="video-synchronization" v-if="!item.fullFile">
                              <img src="@/assets/images/video-synchronization.png" />
                              <div v-if="item.deviceType" class="username-area">
                                <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                              </div>
                            </div>
                          </div>
                          <!-- 抓拍失败 -->
                          <a-tooltip
                            title="由于盖印速度过快，图片抓取失败"
                            overlayClassName="tooltip-custom"
                            color="white"
                            v-if="item.photoType === 2"
                          >
                            <div class="grab-failure-img">
                              <img src="@/assets/images/grab-failure.png" />
                              <div v-if="item.deviceType" class="username-area">
                                <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                              </div>
                            </div>
                          </a-tooltip>
                        </div>
                        <!-- 影像关闭 -->
                        <div v-else class="close-img">
                          <img src="@/assets/images/no-video.png" />
                          <div v-if="item.deviceType" class="username-area">
                            <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="close-img">
                        <img src="@/assets/images/no-image.png" />
                        <div v-if="item.deviceType" class="username-area">
                          <div class="name">{{ sourceType[item.deviceType - 1] }}</div>
                        </div>
                      </div>
                      <div class="stamped-content">
                        <div class="stamped-content-time">
                          <span class="text-name">{{ item.staffName }}</span>
                          <span class="text-time">{{ item.sealTime }}</span>
                        </div>
                        <div class="stamped-content-title">{{ item.address }}</div>
                      </div>
                    </div>
                  </div>
                </a-descriptions-item>
              </a-descriptions>
            </section>
          </section>
          <section class="finger-Archive">
            <archiveDrawer
              v-if="recordinfo.ableReSubmitArchive || recordinfo.ableSubmitArchive"
              @close="$emit('update:visible', false)"
              :closevisible="closevisible"
              :visible="visible"
              :footer="false"
              :documentId="documentId"
              :recordinfo="recordinfo"
              @changeinfo="changeinfo"
              ref="drawerRef"
            />
          </section>
        </div>
      </a-spin>
    </a-modal>
  </section>
</template>

<script>
// import PhotoView from '@/components/PhotoView'
import { defineComponent, reactive, ref, toRefs, onMounted, watch } from 'vue'
import { cmsNotice } from '@/utils/utils'
import archiveDrawer from './archiveDrawer.vue'
export default defineComponent({
  components: {
    // PhotoView,
    archiveDrawer
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => ({ documentFileRelVO: {}, sealName: '' })
    },
    loading: {
      type: Boolean,
      default: true
    },
    documentId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: false
    },
    recordinfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: false
    },
    title: {
      type: String,
      default: '查看'
    }
  },
  setup(props, { emit }) {
    onMounted(() => {
      console.log('props66', props.recordinfo)
    })
    const sourceType = reactive(['天玺', '金盾', '手机', '印控仪', 'APP', '小程序', 'H5'])
    const photoIndex = ref(0)
    let archiveRecord = ref('')
    const previewVisible = ref(false)
    const close = () => {
      console.log('666666666')
    }
    const drawerRef = ref(null)
    const closevisible = () => {
      emit('getList')
    }
    const state = reactive({
      imgList: [],
      isSelect: false,
      processStaffList: [],
      approvalVisible: false,
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0
    })
    const modelRef = reactive({
      mark: undefined,
      process: undefined,
      fileList: [],
      staffList: [] // 自由流程选择的审批人
    })
    const archiveinfo = ref('')
    const changeinfo = value => {
      archiveinfo.value = value
    }

    const previewImg = (src, i, type) => {
      console.log('---123123123----', src)
      if (!src) return
      state.imgList = []
      state.imgList.push(src)
      photoIndex.value = i
      previewVisible.value = true
    }
    const reImg = (e, src, sign = 'a') => {
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      if (sign === 'a') {
        const tipsText = document.createTextNode('点击下载该附件查看')
        tipsSpan.appendChild(tipsText)
        tipsSpan.addEventListener('click', () => {
          window.location.href = src.replace('.com//', '.com/') + '?upload=true'
          setTimeout(() => {
            cmsNotice('success', '正在为你下载，请耐心等待')
          }, 200)
        })
        parentNode.removeChild(e.target)
        parentNode.appendChild(tipsSpan)
      } else {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '74px'
        tipsDiv.style.width = '74px'
        const tipsImg = document.createElement('img')
        tipsImg.src = src
          ? require('@/assets/images/unUpload1.png')
          : require('@/assets/images/video-synchronization.png')
        tipsImg.style.width = '74px'
        tipsImg.style.height = '74px'
        tipsDiv.appendChild(tipsImg)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
      }
    }

    const parentOk = async () => {
      // 检查右侧是否填写
      console.log(drawerRef.value)
      await drawerRef.value.handleOk()
    }

    return {
      sourceType,
      previewVisible,
      photoIndex,
      reImg,
      previewImg,
      changeinfo,
      close,
      closevisible,
      parentOk,
      drawerRef,
      ...toRefs(state),
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        filter(image) {
          return image.dataset.imagetype == 1 || image.dataset.imagetype == 2
        },
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.parent {
  display: flex;
  justify-content: space-around;
  max-height: 500px;
}
.approval-img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  transform: translateY(5px);
  margin-right: 4px;
}

.seal-img {
  width: 63px;
  height: 63px;
  cursor: pointer;
}

.dotted {
  width: 6px;
  height: 6px;
  background: greenyellow;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.time-progess {
  width: 20px;
  height: 20px;
  background: #c3161c;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}

.printing-image {
  border: 1px solid red;
}

.action-box {
  display: flex;
  justify-content: flex-end;
  padding: 10px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  .btn {
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    margin-right: 8px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 32px;
  }

  .close {
    color: #999;
    border: 1px solid #dadada;

    &:hover {
      color: #999;
      border-color: #dadada;
    }
  }

  .cancel {
    color: #c3161c;
    border: 1px solid #c3161c;
  }

  .comfirm {
    background: #c3161c;
    color: #fff;
    border: 1px solid transparent;
    margin-right: 0;

    &:hover {
      border-color: transparent;
    }
  }
}

:deep(.ant-modal-title) {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

:deep(.ant-modal-body) {
  padding: 0;
}
:deep(.ant-modal-footer) {
  padding: 12px 24px;
}
.reason {
  width: 279px;
  height: 40px;
  background: #f6f6f6;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 5px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 19.2px;
  box-sizing: border-box;
  margin-left: 21.6px;
}
.finger-Archive {
  max-width: 548px;
  :deep(.ant-timeline-item-last) {
    padding-bottom: 0;
  }
}

.content {
  padding: 24px 24px;
  padding-right: 0;
  // max-height: 754px;
  overflow-y: auto;
  display: flex;
  position: relative;

  justify-content: space-around;

  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }

  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }

  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }

  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
    // :deep(.ant-descriptions-view) {
    //   width: 540px;
    // }
  }

  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}

.seal-imgs {
  border-radius: 2px;
  display: inline-block;
  margin-right: 16px;
  line-height: 0;
  cursor: pointer;
  margin-bottom: 10px;

  .seal-img {
    width: 74px;
    height: 74px;
  }
}

.stamped-mage {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .stamped-content {
    margin-left: 8px;

    .stamped-content-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 8px;
    }

    .stamped-content-time {
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);

      .text-name {
        display: inline-block;
        color: #333333;
        max-width: 68px;
        margin-right: 16px;
        white-space: nowrap; // 不换行
        overflow: hidden; // 溢出隐藏
        text-overflow: ellipsis; // 超出显示省略号
      }

      .text-time {
        color: #999999;
      }
    }
  }
}

.video-synchronization,
.close-img,
.grab-failure-img {
  position: relative;
  width: 74px;
  height: 74px;

  img {
    width: 74px;
    height: 74px;
  }
}
</style>
<style lang="less">
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }

  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>
<style lang="scss" scoped>
.username-area {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 26px;
  background: #1a1a1b;
  opacity: 0.6;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
  padding: 0 3px;

  .name {
    max-width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
// .footer {
//   width: 100%;
//   text-align: right;
//   height: 52px;
//   padding-right: 20px;

//   .footer-ok {
//     margin-left: 20px;
//   }
// }
.finger-Archive {
  max-height: 754px;
  overflow-y: auto;
}
</style>